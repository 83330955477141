<template>
  <div>
    <b-modal ref="warningDialog" title="Download Selected Studies"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-modal ref="modalRef" :title="title" size="lg" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      no-close-on-esc no-close-on-backdrop hide-header-close
      :cancel-title="cancel ? 'Cancelling...' : 'Cancel'" @cancel="handleCancel()" :cancel-disabled="done || cancel"
      ok-title="Close" @ok="handleOK()" :ok-disabled="!done" :ok-only="done">
      <b-progress :max="checkedStudiesN" show-value>
        <b-progress-bar variant="danger" :value="numErrors"></b-progress-bar>
        <b-progress-bar variant="success" :value="numOK"></b-progress-bar>
      </b-progress>
      <div>{{downloadMsg}}</div>
    </b-modal>
  </div>
</template>

<script>
import dicomWeb from '../common/dicomweb'

export default {
  name: 'downloadCheckedStudies',
  components: {
  },
  data() {
    return {
      checkedStudies: [],
      cancel: false,
      downloadMsg: '',
      done: false,
      groups: [],
      numOK: 0,
      numErrors: 0,
      warningMsg: ''
    }
  },
  computed: {
    checkedStudiesN() {
      return this.checkedStudies.length
    },
    title() {
      const oneOrMore = (this.checkedStudies.length == 1) ? "study" : "studies"
      return "Downloading "+this.checkedStudies.length+" selected "+oneOrMore
    }
  },
  methods: {
    show(groupsForDownload) {
      this.cancel = false
      this.done = false
      this.downloadMsg = ''
      this.groups = groupsForDownload
      this.checkedStudies = []
      this.numOK = 0
      this.numErrors = 0
      this.warningMsg = ''

      // Get studies available for this user to download.
      //
      const allCheckedStudies = this.$store.state.checkedStudies
      for (var g=0; g<this.groups.length; g++) {
        var groupPrefix = this.groups[g]+'|'
        for (let j=0; j<allCheckedStudies.length; j++) {
          if (allCheckedStudies[j].startsWith(groupPrefix)) {
            this.checkedStudies.push({
              group: this.groups[g],
              studyUid: allCheckedStudies[j].replace(groupPrefix, ''),
              ok: false
            })
          }
        }
      }
      if (this.checkedStudies.length > 0) {
        try {
          var options = {
            "mode": "readwrite",
            "startin": "downloads"
          }
          window.showDirectoryPicker(options)
          .then(dirHandle => {
            this.downloadToSelectedDir(dirHandle)
          })
          .catch(e => {
            this.$log.error(`Error selecting directory: ${e.message}`)
            this.warningMsg = "Error selecting directory to download studies.";
          })
        }
        catch(e) {
          this.$log.error(`Error selecting directory: ${e.message}`)
          this.warningMsg = "Browser may not support bulk download. Try using Google Chrome or Microsoft Edge.";
        }
      }
      else {
        this.warningMsg = "Unable to download selected studies, missing download permission for associated group/tenant.";
      }

      if (this.warningMsg != '') {
          this.$refs.warningDialog.show()
      }
    },
    async downloadStudy(dirHandle, checkedStudy) {
      try {
        const entry = this.$store.getters.worklistEntryForStudy(checkedStudy.studyUid)
        var n = 0
        var zipFilename = ''
        var fileExists = false
        do {
          zipFilename = `study_${checkedStudy.studyUid}_${n}.zip`
          if (entry != null) {
            zipFilename = `study_${entry.patient_name}_${entry.patient_id}_${entry.study_date_dcm}_${n}.zip`
          }
          zipFilename = zipFilename.replace(/[/\\?%*:|"<>]/g, '-')
          try {
            await dirHandle.getFileHandle(zipFilename, { create: false })
            fileExists = true
          }
          catch {
            fileExists = false
          }
          n++
          if (n > 100) {
            // Give up
            //
            this.$log.error('Unable to get a unique filename.')
            this.numErrors++
            return
          }
        }
        while (fileExists)

        this.downloadMsg = `Downloading ${zipFilename}.`
        const studyDownloadUrl = await dicomWeb.downloadStudyUrl(entry)
        const handle = await dirHandle.getFileHandle(zipFilename, { create: true });
        const filestream = await handle.createWritable();
        const response = await fetch(studyDownloadUrl)
        await response.body.pipeTo(filestream)
        this.numOK++
        checkedStudy.ok = true
      }
      catch(e) {
        this.$log.error(`Error streaming download to file: ${e.message}`)
        this.numErrors++
      }
    },
    async downloadToSelectedDir(dirHandle) {
      this.$log.debug(`Selected directory ${dirHandle.name}`)
      this.$refs.modalRef.show()

      for (let s=0; s < this.checkedStudies.length; s++) {
        if (this.cancel) {
          break;
        }
        this.$log.debug(`Start downloading ${s}`)
        await this.downloadStudy(dirHandle, this.checkedStudies[s])
        this.$log.debug(`Done downloading ${s}`)
      }
    
      this.done = true
      this.downloadMsg = 'Done.'

      // Remove handled selections from checked selections.
      //
      var updatedCheckedStudies = []
      const allCheckedStudies = this.$store.state.checkedStudies
      for (let s=0; s<allCheckedStudies.length; s++) {
        let parts = allCheckedStudies[s].split('|')
        let downloaded = false
        for (let j=0; j<this.checkedStudies.length; j++) {
          let checkedStudy = this.checkedStudies[j]
          if ((checkedStudy.group == parts[0]) && (checkedStudy.studyUid == parts[1])) {
            downloaded = checkedStudy.ok
            break
          }
        }
        if (!downloaded) {
          updatedCheckedStudies.push(allCheckedStudies[s])
        }
      }

      this.$store.commit("changeCheckedStudies", updatedCheckedStudies)

    },
    handleCancel() {
      this.cancel = true
    },
    handleOK() {
      this.$refs['modalRef'].hide()
    },
    handleWarningOK() {
      this.warningMsg = ''
    }
  }
};
</script>
<style scoped>
.progressDiv {
  height: 100px; 
  width: 100%; 
  overflow: auto;
}
</style>